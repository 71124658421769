@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
@import url('https://netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.css');

@import 'react-toastify/dist/ReactToastify.css';

:root {
  --main-color: #434343;
  --main-color-light: #ced4da;
  --main-color-dark: #303030;
  --text-color: #cfcde7;
  /* --header-height: 1 */
  --nav-header-height: 3.5rem;
  --nav-footer-height: 3rem;
  --main-container-padding: 2rem;
}

/* Landing page header */
.App-header {
  /* background-color: #282c34; */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(80px + 2vmin);
  /* color: white; */
}


/* The main layout */

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  font-family: "Nunito", "Noto Sans", -apple-system, BlinkMacSystemFont, 'Roboto', "Ubuntu", "Droid Sans", 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:before, *:after {
  content: '';
  display: block;
  position: absolute;
}

body {
  overflow-x: hidden;
  /* background-color: #343a40; */
  min-height: 100vh;
}

header {
  display: block;
  background: var(--main-color-dark);
  color: white;
  padding: 0.2rem;
  height: 3.5rem;
}
img {
  max-width: 100%;
}
.wrapper {
  display: flex;
  align-items: stretch;
}

nav {
  background-color: var(--main-color);
  width: 180px;
  padding-top: 1rem;
}

main {
  flex: 1;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
}

/* Top-bar CSS */
header a, .nav-link {
  color: white;
  text-decoration: none;
}

.dropdown-toggle::after {
  content: none; /* remove arrow in dropdown */
}

/* Side-bar CSS */

nav ul {
  list-style: none;
}

nav ul span {
  /* display: block; */
  font-size: 1.1em;
  color: white;
  opacity: .6;
  text-transform: capitalize;
}

nav a {
  color: white;
  cursor: pointer;
  text-decoration: none;
}
nav ul a {
  position: relative;
  display: block;
  padding: 12px 12px 12px 30px;
  color: white;
  /* border-bottom: 1px solid #252525; */
}

nav ul a:hover,
nav ul a.active {
  background: #6e6e6e;
}

.team-head {
  /* width: 50px; */
  padding-inline-start: 100px;
}

th, td {
  padding: 10px;
}


*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:before, *:after {
  content: '';
  display: block;
  position: absolute;
}



/* Sign-in form */
.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* Video Grid */
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.thumbnail-container {
  cursor: pointer;
}

.thumbnail-container img {
  width: 100%;
  height: auto;
}

.duration {
  position: relative;
  bottom: 1.0rem;
  left: 1.0rem;
  padding: 0.25rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 0.8rem;
  border-radius: 5px;
}

/* for tabs */
.internal-tab {
  color: #111;
}

main {
  flex: 1;
  padding: 25px;
  background: #f5f5f5;
}

main header {
  height: 50px;
  margin: -25px -25px 25px -25px;
  padding: 0 25px;
  line-height: 76px;
  font-size: 24px;
  font-weight: 400;
  /* background: #ddd; */
}

.card a:link {
  /* font: 2em sans-serif; */
  text-decoration: none;
  color: white;
}

.accordion-collapse {
  max-height: 80vh;
  overflow-y: scroll;
}

/* .accordion-button.collapsed::before {
  content: "\f058";
  color: green;
  font-weight: 400;
  font-family: "Font Awesome 5 Free";
  padding-right: 12px;
} */

/* dion-button::after {display: none !important;} */

.accordion-right-align {
  width: 100%;
  margin: 0px;
  text-align: right;
}

.active-player {
  border: 3px solid darkcyan; /* or whatever color you prefer */
}

/* Custom CSS */
.large-modal-dialog {
  max-width: 60%; /* Adjust the width as needed */
  /* Optionally adjust other styles, such as height */
  height: 60vh; /* Set the desired height */
}

.modal-content {
  /* max-height: 80vh; Adjust based on requirement */
  overflow: auto; /* Adds scrollbar if content overflows */
  /* width: 90%; Responsive width */
  /* max-width: 600px; Maximum width */
  /* margin: auto; Center the modal */
}



footer {
  margin: auto;
}
footer .copyright {
  text-align: center;
  padding: 25px;
}
footer a:link {
  color: gray;
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}