.notificationboard {
    {{/* padding: 20px; */}}
    {{/* background: #f9f9f9; */}}
    {{/* min-height: 100vh; */}}
  }
  
  h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-size: 48px;
  }
  
  .notifications {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-around;
    gap: 20px;
  }
  
  .link-no-underline {
    text-decoration: none;
    color: inherit; /* Inherit the text color from the parent */
  }

.notification-card {
    background: #6e6e6e;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 380px;
    height: 90px;
    justify-content: space-between;
  }
  
  .notification-type {
    font-weight: 600;
    color: #fff;
    font-size: 24px;
  }
  
  .notification-count {
    background: #e74c3c;
    color: #fff;
    border-radius: 50%;
    padding: 5px 0; /* Adjust padding to ensure it stays circular */
    min-width: 60px; /* Set width to ensure it's a circle */
    height: 60px; /* Set height to ensure it's a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    border-radius: 50%;
    font-size: 24px;
    font-weight: bold;
  }

  .disclaimer-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .disclaimer-title {
    text-align: center; /* This line centers the text */
    font-size: 2em;
    margin-bottom: 10px;
    color: #0056b3;
  }
  
  .disclaimer-subtitle {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #0056b3;
  }
  
  .disclaimer-list {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  .disclaimer-list li {
    margin-bottom: 10px;
  }
  
  .website-name {
    font-weight: bold;
    color: #0056b3;
  }
  
  .contact-info {
    font-weight: bold;
  }
  